import Vue from "vue";
import VueRouter from "vue-router";
import { isLoggedIn } from '@/middleware/auth'

import LandingPage from "@/components/LandingPage.vue";
import UserLogin from "@/components/UserLogin.vue";
import UserRegistrasi from "@/components/UserRegistrasi.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetUserPassword.vue";
import PageNotFound from "@/components/UserPageNotFound.vue";
import ServerErrorPage from "@/components/UserPageServerError.vue";
import AuthorizedErrorPage from "@/components/UserPageAuthorized.vue";
import UserDashboard from "@/components/UserDashboard.vue";
import UserProfile from "@/components/users/Profile.vue";
import UserProfileEdit from "@/components/users/ProfileEdit.vue";
import UserHome from "@/components/users/Home.vue";
import UserEvent from "@/components/users/Event.vue";
import UserArticle from "@/components/users/Article.vue";
import UserReward from "@/components/users/Reward.vue";
import UserRewardAdd from "@/components/users/RewardAdd.vue";
import UserRedeem from "@/components/users/RedeemProduk.vue";
import UserRedeemHistory from "@/components/users/Redeem.vue";
import UserCampaign from "@/components/users/Campaign.vue";
import UserCampaignDetail from "@/components/users/CampaignDetail.vue";
import UserCampaignHistory from "@/components/users/CampaignHistory.vue";
import ChangePassword from "@/components/users/ChangePassword.vue";
import ContactUs from "@/components/support/ContactUs.vue";

import Users from "@/components/administration/users/Users.vue";
import UsersAdd from "@/components/administration/users/UsersAdd.vue";
import UsersEdit from "@/components/administration/users/UsersEdit.vue";
import UsersDetail from "@/components/administration/users/UsersDetail.vue";
import UsersUpload from "@/components/administration/users/UsersUpload.vue";

import Role from "@/components/administration/role/Role.vue";
import RoleAdd from "@/components/administration/role/RoleAdd.vue";
import RoleDetail from "@/components/administration/role/RoleDetail.vue";
import RoleEdit from "@/components/administration/role/RoleEdit.vue";
import RoleUpload from "@/components/administration/role/RoleUpload.vue";

import Permission from "@/components/administration/permission/Permission.vue";
import PermissionAdd from "@/components/administration/permission/PermissionAdd.vue";
import PermissionDetail from "@/components/administration/permission/PermissionDetail.vue";
import PermissionEdit from "@/components/administration/permission/PermissionEdit.vue";
import PermissionUpload from "@/components/administration/permission_role/PermissionUpload.vue";

import PermissionRole from "@/components/administration/permission_role/PermissionRole.vue";
import PermissionRoleAdd from "@/components/administration/permission_role/PermissionRoleAdd.vue";
import PermissionRoleEdit from "@/components/administration/permission_role/PermissionRoleEdit.vue";
import PermissionRoleDetail from "@/components/administration/permission_role/PermissionRoleDetail.vue";

import routesCommunity from "@/components/master/community/routes";
import routesEvent from "@/components/master/event/routes";
import routesEventEmployee from "@/components/master/event-employee/routes";
import routesEventType from "@/components/master/event-type/routes";
import routesSetting from "@/components/master/setting/routes";
import routesEventForm from "@/components/transaction/event-form/routes";
import routesEventEmployeeForm from "@/components/transaction/event-employee-form/routes";
import routesRedeemProduk from "@/components/transaction/redeem-produk/routes";
import routesCampaign from "@/components/transaction/campaign/routes";
import routesCampaignUser from "@/components/transaction/campaign-user/routes";
import routesProduk from "@/components/master/produk/routes";
import routesBanner from "@/components/master/banner/routes";
import routesArticle from "@/components/master/article/routes";
import routesWaGroup from "@/components/master/wa-group/routes";

import Event from "@/components/master/event/Index";
import EventEmployee from "@/components/master/event-employee/Index";
import EventType from "@/components/master/event-type/Index";
import Setting from "@/components/master/setting/Index";
import EventForm from "@/components/transaction/event-form/Index";
import EventEmployeeForm from "@/components/transaction/event-employee-form/Index";
import RedeemProduk from "@/components/transaction/redeem-produk/Index";
import Campaign from "@/components/transaction/campaign/Index";
import CampaignUser from "@/components/transaction/campaign-user/Index";
import Community from "@/components/master/community/Index";
import Produk from "@/components/master/produk/Index";
import Banner from "@/components/master/banner/Index";
import Article from "@/components/master/article/Index";
import WaGroup from "@/components/master/wa-group/Index";


Vue.use(VueRouter);

const routes = [

  /**
   * Testing
   */
  {
    path: "/",
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "LandingPage",
    component: LandingPage
  },

  {
    path: "/login",
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "UserLogin",
    component: UserLogin
  },
  {
    path: "/registrasi",
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "UserRegistrasi",
    component: UserRegistrasi
  },
  {
    path: "/forgot-password",
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: "/reset-password/:email/:token",
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "ResetPassword",
    component: ResetPassword,
    props: true
  },
  {
    path: '/server-error',
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "ServerErrorPage",
    component: ServerErrorPage
  },
  {
    path: '/authorized-error',
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "AuthorizedErrorPage",
    component: AuthorizedErrorPage
  },
  {
    path: '/page-not-found',
    meta: {
      title: "BERT",
      allowAnonymous: true
    },
    name: "PageNotFound",
    component: PageNotFound
  },
  {
    path: '*',
    meta: {
      title: "BERT"
    },
    name: "PageNotFoundArea",
    component: PageNotFound
  },
  {
    path: '/dashboard',
    meta: {
      title: "BERT",
    },
    name: "UserDashboard",
    component: UserDashboard
  },

  
  /**
   * User
   */
  {
    path: '/user/home',
    meta: {
      title: "BERT",
    },
    name: "UserHome",
    component: UserHome
  },
  {
    path: '/user/profile',
    meta: {
      title: "BERT",
    },
    name: "UserProfile",
    component: UserProfile
  },
  {
    path: '/user/profile/edit',
    meta: {
      title: "BERT",
    },
    name: "UserProfileEdit",
    component: UserProfileEdit
  },
  {
    path: '/user/article',
    meta: {
      title: "BERT",
    },
    name: "UserArticle",
    component: UserArticle
  },
  {
    path: '/user/event',
    meta: {
      title: "BERT",
    },
    name: "UserEvent",
    component: UserEvent
  },
  {
    path: '/user/reward',
    meta: {
      title: "BERT",
    },
    name: "UserReward",
    component: UserReward
  },
  {
    path: '/user/reward/add',
    meta: {
      title: "BERT",
    },
    name: "UserRewardAdd",
    component: UserRewardAdd
  },
  {
    path: '/user/redeem',
    meta: {
      title: "BERT",
    },
    name: "UserRedeem",
    component: UserRedeem
  },
  {
    path: '/user/redeem-history',
    meta: {
      title: "BERT",
    },
    name: "UserRedeemHistory",
    component: UserRedeemHistory
  },

  {
    path: '/user/campaign',
    meta: {
      title: "BERT",
    },
    name: "UserCampaign",
    component: UserCampaign
  },
  {
    path: '/user/campaign-detail/:id',
    meta: {
      title: "BERT",
    },
    name: "UserCampaignDetail",
    component: UserCampaignDetail
  },

  {
    path: '/user/campaign-history',
    meta: {
      title: "BERT",
    },
    name: "UserCampaignHistory",
    component: UserCampaignHistory
  },

  
  {
    path: '/change-password',
    meta: {
      title: "BERT",
    },
    name: "ChangePassword",
    component: ChangePassword
  },


  /**
   * Support
   */
  {
    path: '/contact-us',
    meta: {
      title: "BERT",
    },
    name: "ContactUs",
    component: ContactUs
  },


  /**
   * Administration/Users
   */
  {
    path: '/users',
    meta: {
      title: "BERT",
    },
    name: "Users",
    component: Users
  },
  {
    path: '/users/add',
    meta: {
      title: "BERT",
    },
    name: "UsersAdd",
    component: UsersAdd
  },

  {
    path: '/users/edit/:id',
    meta: {
      title: "BERT",
    },
    name: "UsersEdit",
    component: UsersEdit
  },


  {
    path: '/users/detail/:id',
    meta: {
      title: "BERT",
    },
    name: "UsersDetail",
    component: UsersDetail
  },

  {
    path: '/users/upload',
    meta: {
      title: "BERT",
    },
    name: "UsersUpload",
    component: UsersUpload
  },
  
  /**
   * Administration/Role
   */
  {
    path: '/role',
    meta: {
      title: "BERT",
    },
    name: "Role",
    component: Role
  },


  {
    path: '/role/add',
    meta: {
      title: "BERT",
    },
    name: "RoleAdd",
    component: RoleAdd
  },

  {
    path: '/role/edit/:id',
    meta: {
      title: "BERT",
    },
    name: "RoleEdit",
    component: RoleEdit
  },

  {
    path: '/role/detail/:id',
    meta: {
      title: "BERT",
    },
    name: "RoleDetail",
    component: RoleDetail
  },


  {
    path: '/role/upload',
    meta: {
      title: "BERT",
    },
    name: "RoleUpload",
    component: RoleUpload
  },



  /**
   * Administration/Permission
   */
  {
    path: '/permission',
    meta: {
      title: "BERT",
    },
    name: "Permission",
    component: Permission
  },

  {
    path: '/permission/add',
    meta: {
      title: "BERT",
    },
    name: "PermissionAdd",
    component: PermissionAdd
  },

  {
    path: '/permission/edit/:id',
    meta: {
      title: "BERT",
    },
    name: "PermissionEdit",
    component: PermissionEdit
  },

  {
    path: '/permission/detail/:id',
    meta: {
      title: "BERT",
    },
    name: "PermissionDetail",
    component: PermissionDetail
  },


  {
    path: '/permission/upload',
    meta: {
      title: "BERT",
    },
    name: "PermissionUpload",
    component: PermissionUpload
  },



  /**
   * Administration/Permission
   */
  {
    path: '/rolpermis',
    meta: {
      title: "BERT",
    },
    name: "Permission Role",
    component: PermissionRole
  },

  {
    path: '/rolpermis/add',
    meta: {
      title: "BERT",
    },
    name: "PermissionRoleAdd",
    component: PermissionRoleAdd
  },

  {
    path: '/rolpermis/edit/:id',
    meta: {
      title: "BERT",
    },
    name: "PermissionRoleEdit",
    component: PermissionRoleEdit
  },

  {
    path: '/rolpermis/detail/:id',
    meta: {
      title: "BERT",
    },
    name: "PermissionRoleDetail",
    component: PermissionRoleDetail
  },


  {
    path: '/community',
    meta: {
      title: "BERT",
    },
    children:routesCommunity,
    component: Community
  },
  
  {
    path: '/event',
    meta: {
      title: "BERT",
    },
    children:routesEvent,
    component: Event
  },

  {
    path: '/event-employee',
    meta: {
      title: "BERT",
    },
    children:routesEventEmployee,
    component: EventEmployee
  },

  {
    path: '/event-type',
    meta: {
      title: "BERT",
    },
    children:routesEventType,
    component: EventType
  },

  {
    path: '/setting',
    meta: {
      title: "BERT",
    },
    children:routesSetting,
    component: Setting
  },

  {
    path: '/event-form',
    meta: {
      title: "BERT",
    },
    children:routesEventForm,
    component: EventForm
  },
  {
    path: '/event-employee-form',
    meta: {
      title: "BERT",
    },
    children:routesEventEmployeeForm,
    component: EventEmployeeForm
  },

  {
    path: '/redeem-produk',
    meta: {
      title: "BERT",
    },
    children:routesRedeemProduk,
    component: RedeemProduk
  },

  {
    path: '/campaign',
    meta: {
      title: "BERT",
    },
    children:routesCampaign,
    component: Campaign
  },

  {
    path: '/campaign-user',
    meta: {
      title: "BERT",
    },
    children:routesCampaignUser,
    component: CampaignUser
  },

  {
    path: '/produk',
    meta: {
      title: "BERT",
    },
    children:routesProduk,
    component: Produk
  },

  {
    path: '/banner',
    meta: {
      title: "BERT",
    },
    children:routesBanner,
    component: Banner
  },

  {
    path: '/article',
    meta: {
      title: "BERT",
    },
    children:routesArticle,
    component: Article
  },

  {
    path: '/wa-group',
    meta: {
      title: "BERT",
    },
    children:routesWaGroup,
    component: WaGroup
  },

]

const router = new VueRouter({
  mode: "history",
  routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (to.name == 'UserLogin' && isLoggedIn() || to.name == 'ForgotPassword' && isLoggedIn() || to.name == 'ResetPassword' && isLoggedIn()) {

    // var userModel = JSON.parse(localStorage.getItem("userModels"));

    next({ path: '/dashboard' })

    // if (userModel.sub.role.name == 'root_ct') {
    //   next({ path: '/dashboard-ct' })
    // } else if (userModel.sub.role.name == 'root_faktur_pajak' && userModel.sub.role.name == 'user_faktur_pajak') {
    //   next({ path: '/dashboard-fp' })
    // } else {
    //   next({ path: '/dashboard' })
    // }

  }
  else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
});


export default router;
