import EventEmployeeForm from "@/components/transaction/event-employee-form/EventEmployeeForm.vue";
import EventEmployeeFormAdd from "@/components/transaction/event-employee-form/EventEmployeeFormAdd.vue";
import EventEmployeeFormDetail from "@/components/transaction/event-employee-form/EventEmployeeFormDetail.vue";
import EventEmployeeFormEdit from "@/components/transaction/event-employee-form/EventEmployeeFormEdit.vue";
import EventEmployeeFormUpload from "@/components/transaction/event-employee-form/EventEmployeeFormUpload.vue";

let routesEventEmployeeForm= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeForm",
        component: EventEmployeeForm
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeFormAdd",
        component: EventEmployeeFormAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeFormEdit",
        component: EventEmployeeFormEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeFormDetail",
        component: EventEmployeeFormDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeFormUpload",
        component: EventEmployeeFormUpload
      },
    
]

export default routesEventEmployeeForm
  