<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="my-component">

        <!-- </div> class="main-wrapper"> -->
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">
        <div class='parent-login'>
          <div class='section-help'>
            <div class="header">
              <a target="_blank"
                href="https://wa.me/6287831751586?text=Mohon%20bantuan%20atas%20kendala%20yang%20saya%20alami%20yaitu:"><img
                  src="/assets/images/operator.png" style="width:40px;height:auto;"><br/>
                CS Support</a>
            </div>
          </div>
          <div class='section-login'>
            <img src="/assets/images/logo.png" alt="Logo" />

            <form @submit.prevent="submitData" method="POST" autocomplete="off">
              <input type="email" id='email' name="email" placeholder="Email" v-model="forms.username" autofocus
                required />
                <div class="container-password">
                  <input v-if="showPassword" type="text" placeholder="Kata Sandi" v-model="forms.password" required/>
                  <input v-else type="password" placeholder="Kata Sandi" v-model="forms.password" required>
                  
                  
                  
                  <div class="feather-eye">
                    <a @click="toggleShow"><span class="icon is-small is-right">
                    <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                  </span>
                </a>
                </div>



                </div>
              <!-- { errorMessage && (<div class="error">{errorMessage}</div>) } -->
              <input type="submit" value="Masuk" />
            </form>

            <div class='navigation'>
              <a href="/forgot-password"><b>Lupa password? </b></a>
              <!--a target="_blank" href="https://wa.me/6281326035476?text=Halo%2C%20saya%20ingin%20bekerjasama%20dengan%20Ibu2Canggih"><img src="/assets/images/icon_wa.png" style="width:30px;height:auto;"></a-->
              <br/><br/>
              Belum punya Akun?
              <a href="/registrasi"><b> Daftar disini</b></a>
            </div>
          </div>
        </div>
        <div class='footer-helper'>
          <information-component classMenu="InformationIndex"></information-component>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
// import { setSessionChainToken, setAuthLang, setSessionCtToken,setSessionFpToken,setSessionGtToken} from "@/middleware/setBackendSessions";
import { setAuthToken, getUserInfo } from "@/middleware/auth";
import informationComponent from "@/components/_partials/information";
// import VueCaptcha from "vue-captcha-code";

export default {
  name: "UserLogin",
  components: {
    "information-component": informationComponent
    // VueCaptcha,
  },
  data() {
    return {
      showPassword:false,
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      langs: ["id", "en"],
      forms: { username: "", password: "" },
    };
  },
  watch: {},
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    handleChange(code) {
      this.code = code;
    },
    // refreshCaptchaCode() {
    //   this.$refs.captcha.refreshCaptcha();
    // },
    makeOffer() {

    },
    submitData() {

      if (this.forms.username.trim()) {
        let formData = new FormData();
        formData.append("username", this.forms.username.trim());
        formData.append("password", this.forms.password);

        const baseURI = this.$settings.endPoint + "auth/login";

        this.$http
          .post(baseURI, formData)
          .then((response) => {
            this.loading();
            if (response.data.status === 200) {
              setAuthToken(response.data.datas.access_token);

              var userInfo = getUserInfo(response.data.datas.access_token);
              localStorage.setItem("userModels", JSON.stringify(userInfo));
              // var userModel=JSON.parse(localStorage.getItem("userModels"));

              // const userDatas = this.$getUserInfo();
              // this.name = userDatas.sub.name;
              console.log(userInfo)
              if (userInfo.sub.role_id !== 2 && userInfo.sub.role_id !== 6) {
                window.location.href = "/dashboard";
              } else {
                window.location.href = "/user/home";
              }
            } else {
              // this.forms.captchaCode = "";
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
              // this.refreshCaptchaCode();
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
            // this.forms.captchaCode = "";
            // this.refreshCaptchaCode();
          });
      } else {
        this.error("email required");
      }

    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    // langChanged(lang) {
    //   if (this.locale == "") {
    //     this.locale = lang;
    //     setAuthLang(lang);
    //   } else {
    //     this.$i18n.locale = this.locale;
    //     localStorage.Lang = this.locale;
    //     setAuthLang(this.locale);
    //   }
    // },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  created: function () { },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  mounted() {
    this.loading();
    // this.langChanged(this.$i18n.locale);
  },
};
</script>
<style scoped>
@import '_partials/partial.css';
.container-password{
  width: 100%;
  position: relative;
}

.container-password .feather-eye{
  position: absolute;
  top: 20%;
  right: 4%;
  cursor: pointer;
  color: lightgray;
}

.container-password input[type="text"] {
  padding: 0px 24px;
}

</style>