import EventEmployee from "@/components/master/event-employee/EventEmployee.vue";
import EventEmployeeAdd from "@/components/master/event-employee/EventEmployeeAdd.vue";
import EventEmployeeDetail from "@/components/master/event-employee/EventEmployeeDetail.vue";
import EventEmployeeEdit from "@/components/master/event-employee/EventEmployeeEdit.vue";
import EventEmployeeUpload from "@/components/master/event-employee/EventEmployeeUpload.vue";

let routesEventEmployee= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "EventEmployee",
        component: EventEmployee
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeAdd",
        component: EventEmployeeAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeEdit",
        component: EventEmployeeEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeDetail",
        component: EventEmployeeDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "EventEmployeeUpload",
        component: EventEmployeeUpload
      },
    
]

export default routesEventEmployee
  