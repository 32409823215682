<template>
  <div>
    <router-view />
  </div>
</template>
<script>

export default {
  name: "InformasiEventEmployeeForm",
  data () {
    return {}
  },
  created () {

  },
  methods: {
  
  },
}

</script>
